header{
    padding-top:  7rem;
    height: 50rem;
    overflow: hidden;
  }
  .header_container{
    text-align: center;
    height: 100%;
    position: relative;
  }
  
  /*----------Header Socials--------*/
  .header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
  }
  
  .header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
  }
  /*----------Me--------*/
  .me{
    background: linear-gradient(var(--color-primary),transparent);
    width: 22rem;
    height:30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow:hidden;
    padding: 12rem 1.5rem 1.5rem 1.5rem;
  }

  #header-image {
    border-radius: 3rem;
    transform: rotate(-5deg);
    width:92%;
    margin-left: 0.8rem;
  }
  
  /*----------Scroll Down--------*/
  .scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
  }
  
  /*---------Media Queries(Medium Devices)--------*/
  
  @media screen and (max-width: 1024px){
    .header{
      height: 68vh;
    }
  }
  /*---------Media Queries(SMALL Devices)--------*/
  
  @media screen and (max-width: 600px){
    .header{
      height: 68vh;
    }
    .header_socials,.scroll_down{
      display: none;
    }
  
  }