.experience_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
  }
  
  .experience_container > div {
    background: var(--color-bg-variant);
    padding: 2rem 2rem;
    border-radius: 3rem;
    border: 1px solid transparent;
    transition: var(--transition);
  }
  
  .experience_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
  }
  .experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
  }
  
  .experience_details {
    display: flex;
    gap: 1rem;
  }
  
  .experience_details-icon {
    font-size: 1.2rem; /* Adjust as needed */
    color: var(--color-primary);
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  
  
  /*---------Media Queries(Medium Devices)--------*/
  
  @media screen and (max-width: 1024px) {
    .experience_container {
      grid-template-columns: 1fr;
    }
    .experience_container > div {
      width: 80%;
      margin: 0 auto;
      padding: 2.4rem;
    }
    .experience_content {
      padding: 2rem;
    }
  }
  /*---------Media Queries(SMALL Devices)--------*/
  
  @media screen and (max-width: 600px) {
    .experience_container {
      gap: 1rem;
    }
  
    .experience_container > div {
      width: 100%;
      padding: 2rem 2rem;
    }
  }
  