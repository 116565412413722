.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
  
  .portfolio_item{
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    height: fit-content;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
  }
  
  .portfolio_item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
  }
  
  .portfolio_item-image{
    padding: 1.3rem;
    overflow: hidden;
  }
  .portfolio_item h3{
    padding: 1.3rem;
  }
  .portfolio_item-cta{
    display: flex;
    padding: 1.8rem;
    gap:0.5rem;
  }
  /*---------Media Queries(Medium Devices)--------*/
  
  @media screen and (max-width: 1024px) {
    .portfolio_container{
      grid-template-columns:1fr 1fr;
      gap: 1.9rem;
    }
  }
  /*---------Media Queries(SMALL Devices)--------*/
  
  @media screen and (max-width: 600px) {
    .portfolio_container{
      grid-template-columns: 1fr;
      gap:1rem;
    }
  
  }
  