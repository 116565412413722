.contact_container {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .contact_container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
  }
  
  .contact_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
  }
  .contact_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  
  .contact_details {
    display: flex;
    gap: 1rem;
  }
  
  .contact_details-icon {
    margin-top: 6px;
    color: var(--color-primary);
  }
  
  /*---------Media Queries(Medium Devices)--------*/
  
  @media screen and (max-width: 1024px) {
    .contact_container {
      grid-template-columns: 1fr;
    }
    .contact_container > div {
      width: 80%;
      margin: 0 auto;
      padding: 2.4rem;
    }
    .contact_content {
      padding: 1rem;
    }
  }
  /*---------Media Queries(SMALL Devices)--------*/
  
  @media screen and (max-width: 600px) {
    .contact_container {
      gap: 1rem;
    }
  
    .contact_container > div {
      width: 100%;
      padding: 2rem 1rem;
    }
  }
  